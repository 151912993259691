<template>
    <div class="app-list">
        <b-row>
            <div class="w-100 bg-white p-3 border-gray border-bottom">
                <div class="d-flex justify-content-between align-items-center">
                    <p class="text-gray">Filtres</p>
                    <div class="text-gray d-flex flex-column align-items-end">
                        <div class="d-flex align-items-center small">
                            du
                            <b-form-input class="app-list__date mx-1" v-model="filters.min" size="sm" type="date" @change="filterList()"> </b-form-input>
                            au
                            <b-form-input class="app-list__date ml-1" v-model="filters.max" size="sm" type="date" @change="filterList()"> </b-form-input>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-1">
                    <div class="d-flex align-items-center row">
                        <div class="col-6 d-flex flex-column">
                            <p class="small text-nowrap text-gray">par type</p>
                            <b-form-select class="ml-1 form-control" size="sm" v-model="filters.type" @change="filterList()">
                                <b-form-select-option v-for="(state, index) in filterStates" :value="state.value" :key="index">{{ state.label }} </b-form-select-option>
                            </b-form-select>
                        </div>
                        <div class="col-6 d-flex flex-column">
                            <p class="small text-nowrap text-gray">par contrat</p>
                            <b-form-select class="ml-1" size="sm" v-model="filters.contract" @change="filterList()">
                                <b-form-select-option :value="null">Tous </b-form-select-option>
                                <b-form-select-option v-for="(num_contract, idcontract) in contrats" :value="idcontract" :key="`option-${idcontract}`"
                                    >contrat n°{{ num_contract }}
                                </b-form-select-option>
                            </b-form-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-100 bg-white p-3 mb-0 mb-sm-5">
                <!--                <states-caption :in-planning="false" class="mb-3"/>-->
                <div v-if="filteredList.length > 0">
                    <div
                        v-for="(item, index) in filteredList"
                        class="d-flex justify-content-between align-items-center border-gray"
                        :class="{ 'pt-2': index > 0, 'pb-2 border-bottom': index < filteredList.length - 1 }"
                        :key="`interv-${index}`"
                    >
                        <div class="pr-4 py-1">
                            <p :class="[`text-${states[item.state].color}`]">
                                <span class="font-weight-bold">{{ formatDate(item.intervention_date, "_D_ _Month_ _Y_") }}</span>
                                - {{ formatTime(item.start_time_billable, "_H_h_Min_") }}-{{ formatTime(item.end_time_billable, "_H_h_Min_") }}<template v-if="isGfs"> - {{ item.kms }}km</template>
                                <span v-if="displayStateName.includes(item.state)" class="small"> ({{ states[item.state].text }} <template v-if="item.billed"> - facturée</template>)</span>
                            </p>
							<p><template v-if="item.employee_lastname || item.employee_firstname">{{ item.employee_lastname }} {{ item.employee_firstname }} -</template>
								{{ item.name }}</p>
                        </div>
                        <b-link
                            class="text-nowrap"
                            @click="
                                () => {
                                    dateAction(item);
                                }
                            "
                            >Voir
                            <b-icon-eye class="h3" />
                        </b-link>
                    </div>
                </div>
                <p v-else>Aucune prestation à afficher.</p>
            </div>
        </b-row>
    </div>
</template>

<script>
import { BIconEye } from "bootstrap-vue";
import StatesCaption from "@/components/layout/elements/StatesCaption";
import formatDate from "@/mixins/format-date";
import formatTime from "@/mixins/format-time";

export default {
    name: "app-list",
    mixins: [formatDate, formatTime],
    components: { StatesCaption, BIconEye },
    props: {
        interventions: Array,
        contrats: Object,
        currentContrat: {
            type: Number,
            required: false,
            default: null,
        },
        dateAction: Function,
    },
    watch: {
        interventions(newVal) {
            this.filterList();
        },
        "filters.max": function (newVal) {
            const today = this.$moment().format("YYYY-MM-DD");
            if (newVal > today) {
                this.$nextTick(() => {
                    this.filters.max = today;
                });
            }
        },
    },
    data() {
        return {
            filteredList: [],
            filters: {
                min: "",
                max: "",
                contract: null,
                type: 0,
            },
            filterStates: [
                { label: "Tous", value: 0 },
                { label: "En cours de validation", value: "verifying" },
                { label: "Effectués", value: "validated" },
            ],
            displayStateName: ["cancelled", "verifying"],
            states: this.$appVersion("states"),
        };
    },
    mounted() {
        this.filters.min = this.$moment().startOf("month").format("YYYY-MM-DD");
        this.filters.max = this.$moment().endOf("month").format("YYYY-MM-DD");

        this.filters.contract = this.$props.currentContrat;
    },
    computed: {
        isGfs() {
            return location.host.includes("family");
        },
    },
    methods: {
        filterList() {
            let list = this.$props.interventions;

            if (this.filters.min) {
                list = list.filter((item) => item.intervention_date >= this.filters.min);
            }

            if (this.filters.max) {
                list = list.filter((item) => item.intervention_date <= this.filters.max);
            }

            if (this.filters.contract) {
                list = list.filter((item) => item.contract_idcontract == this.filters.contract);
            }

            if (this.filters.type) {
                list = list.filter((item) => item.state == this.filters.type);
            }

            this.filteredList = list;
        },
    },
};
</script>
