<template>
    <div>
        <b-modal v-if="data"
                 ref="modal"
                 hide-footer>
            <template #modal-header>
                <p class="merci-bold h4 text-center text-secondary text-capitalize border-bottom border-secondary w-100 pt-4 pb-1">
                    {{ data.title }}</p>
                <b-button-close @click="() => { $refs.modal.hide() }"></b-button-close>
            </template>
            <p class="text-center"
               :class="[`text-${states.upcoming.color}`]">{{ data.time }}</p>
            <p class="text-center">{{ data.name }}</p>
            <hr>
            <div v-if="data.invoice" class="text-center mt-2">
                <b-link @click="getInvoice(data.invoice)" class="text-nowrap">
                        Voir la facture
                    <b-icon-eye class="h4"/>
                </b-link>
            </div>
            <hr>
            <b-form v-if="data.state === 'upcoming'"
                    @submit.prevent="submit(data)">
                <div v-if="isTooLate(data, 1)">
                    <div class="text-justify mb-1">
                        <strong>Délai dépassé</strong>
                    </div>
                    Nous vous invitons à contacter le service client au
                    <b-link class="" :href="`tel:${getPhone()}`">
                        <span class="line-height-fit" style="white-space: nowrap">{{ getPhone() }}</span>
                    </b-link>
                    pour toute demande concernant votre prochaine prestation.
                    Le délai imparti ne nous permet pas de vous garantir son traitement.
                </div>
                <div v-else>
                    <p class="text-primary text-center mb-3" @click="toggleConsigne()">{{ showConsigne ? '> ' : '' }}
                        Ajouter une consigne</p>
                    <p v-if="!isTooLate(data, 3)" class="text-primary text-center mb-3" @click="toggleAnnuler()">
                        {{ showAnnuler ? '> ' : '' }}
                        Déplacer / Annuler le créneau</p>
                    <div v-else class="text-justify">
                        Nous vous invitons à contacter le service client au
                        <b-link class="" :href="`tel:${getPhone()}`">
                            <span class="line-height-fit" style="white-space: nowrap">{{ getPhone() }}</span>
                        </b-link>
                        pour toute demande de modification concernant cette prochaine prestation. Le délai imparti ne
                        nous permet pas de vous garantir son traitement automatique.
                    </div>
                    <transition name="fade" mode="out-in">
                        <div v-if="showForm">
                            <b-form-textarea class="mb-2"
                                             v-if="showAnnuler"
                                             v-model="instructionsForm.annulation"
                                             placeholder="Description"
                                             rows="4"
                                             max-rows="15"
                                             no-resize
                                             required></b-form-textarea>
                            <b-form-textarea class="mb-2"
                                             v-if="showConsigne"
                                             v-model="instructionsForm.instruction"
                                             placeholder="Description"
                                             rows="4"
                                             max-rows="15"
                                             no-resize
                                             required></b-form-textarea>
                            <div class="d-flex justify-content-center">
                                <b-button size="lg"
                                          type="submit"
                                          variant="primary">Envoyer
                                </b-button>
                            </div>
                        </div>
                    </transition>
                </div>
            </b-form>
            <b-form v-if="data.type === 'pointage'" @submit.prevent="() => { issueAction(data.id) }">
                <b-link class="text-danger d-block text-center"
                        @click="hasIssue = !hasIssue">
                    <b-icon-plus-circle-fill class="h3"/>
                    Nous contacter
                </b-link>
                <template v-if="hasIssue">
                    <b-form-select class="mt-3 mb-2"
                                   :class="{ 'text-gray': !issueForm.type }"
                                   v-model="issueForm.type"
                                   required>
                        <b-form-select-option :value="null"
                                              disabled>Votre demande concerne
                        </b-form-select-option>
                        <b-form-select-option v-for="option in options"
                                              :value="option.value"
                                              :key="`option-${option.value}`">{{ option.text }}
                        </b-form-select-option>
                    </b-form-select>
                    <b-form-textarea class="mb-2"
                                     v-model="issueForm.description"
                                     placeholder="Description"
                                     rows="4"
                                     max-rows="15"
                                     no-resize
                                     required></b-form-textarea>
                    <div class="d-flex justify-content-center">
                        <b-button size="lg"
                                  type="submit"
                                  variant="primary">Envoyer
                        </b-button>
                    </div>
                </template>
            </b-form>
            <template>
            </template>
        </b-modal>
        <PdfViewer
            v-if="promise"
            :promise="promise"
            @exit-pdf="promise = null"
        ></PdfViewer>
    </div>
</template>

<script>
import {BIconPlusCircleFill} from 'bootstrap-vue'
import contratsService from "@/services/contratsService";
import AlertHandler from "@/alertHandler";
import PdfViewer from "../../layout/elements/PdfViewer";
import downloadFile from "@/mixins/download-file";
import * as moment from "moment"


export default {
    name: 'agenda-modal',
    components: {BIconPlusCircleFill, PdfViewer},
    mixins: [downloadFile],
    props: {
        data: Object,
    },
    data() {
        return {
            moment: moment,
            hasIssue: false,
            showAnnuler: false,
            showConsigne: false,
            promise: null,
            firstOfMonth: this.$moment().date(1).format('YYYY-MM-DD'),
            etabs: [],
            instructionsForm: {
                instruction: '',
                annulation: '',
            },
            issueForm: {
                type: null,
                description: '',
            },
            options: [],
            states: this.$appVersion('states')
        }
    },
    computed: {
        showForm() {
            return this.showAnnuler || this.showConsigne;
        }
    },
    async mounted() {
        try {
            const res = await contratsService.getEtabs();
            if (res.etat == 'KO') throw 'KO'
            this.etabs = res.data.data;
        } catch (error) {
            AlertHandler.show({code: 4022, error});
        }

        this.options = [
            {
                value: 'Intervenant absent',
                text: "Ma prestation n'a pas été réalisée"
            },
            {
                value: 'Modification horaires',
                text: "Je souhaite modifier les horaires d'arrivée / départ"
            },
            {
                value: 'Autre',
                text: "J'ai une autre demande"
            }
        ]
    },
    methods: {
        instructionAction(id) {
            this.$emit('instructionAdded', {id_slot: id, message: this.instructionsForm.instruction})
            this.$refs.modal.hide()
        },
        annulationAction(id) {
            this.$emit('annulationAdded', {id_slot: id, message: this.instructionsForm.annulation})
            this.$refs.modal.hide()
        },
        issueAction(id) {
            const message = this.issueForm.type ? this.issueForm.type + ' : ' + this.issueForm.description : this.issueForm.description

            this.$emit('reportCreated', {id_intervention: id, message: message})
            this.$refs.modal.hide()
        },
        modalAction() {
            this.issueForm.type = null
            this.issueForm.description = ''
            this.hasIssue = false
            this.instructionsForm.instruction = this.data?.comment;

            this.$nextTick(() => {
                this.$refs.modal.show()
            })
        },
        toggleAnnuler() {
            this.showConsigne = false;
            this.$nextTick(() => {
                this.showAnnuler = !this.showAnnuler;
            })
        },
        toggleConsigne() {
            this.showAnnuler = false;
            this.$nextTick(() => {
                this.showConsigne = !this.showConsigne;
            })
        },
        getInvoice(idinvoice) {
            this.promise = contratsService.getFacture(idinvoice)
        },
        submit(data) {
            if (this.showConsigne) this.instructionAction(data.id);
            if (this.showAnnuler) this.annulationAction(data.id)
        },
        getPhone() {
            if (this.etabs.length && this.etabs[0].franchise) {
                return this.etabs[0].phone;
            }

            return "09 69 375 600";
        },
        isTooLate(data, days = 3) {
            let m = moment()

            if (moment(data.raw_date) <= m) return true;

            for (let n = 0; n < days; m.add(1, 'days')) {
                if ([0, 6].indexOf(m.day()) === -1) n++;
            }

            return moment(data.raw_date) <= m;
        }
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>