import store from '../store'

export default {
    baseUrl: process.env.VUE_APP_API_URL,
    getInterventions(params) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            },
            params
        }
        let url = this.baseUrl + 'api-m/einstein/prestations'

        return window.axios.get(url, config)
    },
    getPlanning() {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + 'api-m/einstein/prestations/planning'

        return window.axios.get(url, config)
    },
    addNoteToIntervention(params) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + 'api-m/einstein/prestations/ajouter-consigne'

        return window.axios.post(url, params, config)
    },
    cancelIntervention(params) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + 'api-m/einstein/prestations/cancel-intervention'

        return window.axios.post(url, params, config)
    },
    sendReportToIntervention(params) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + 'api-m/einstein/prestations/signaler-probleme'

        return window.axios.post(url, params, config)
    }
}