<template>
    <div class="app-calendar">
        <p v-for="(day, index) in headers" :key="`day-${index}`" class="app-calendar__head family-bold">{{ day }}</p>
        <div v-for="(date, i) in calendar" :key="`option-${i}`" class="app-calendar__cell family-bold">
            <div
                class="app-calendar__tag"
                :class="dateState(date)"
                @click="
                    () => {
                        cellAction(date);
                    }
                "
            >
                {{ date.day }}
            </div>
        </div>
    </div>
</template>

<script>
import formatDate from "@/mixins/format-date";

export default {
    name: "app-calendar",
    mixins: [formatDate],
    props: {
        items: Array,
        dateAction: Function,
    },
    data() {
        return {
            headers: ["L", "M", "M", "J", "V", "S", "D"],
            calendar: [],
            states: this.$appVersion("states"),
        };
    },
    created() {
        this.generateCalendar();
    },
    watch: {
        items() {
            this.generateCalendar();
        },
    },
    methods: {
        dateState(date) {
            return [
                {
                    "app-calendar__tag--in": !date.out && !date.state,
                    "app-calendar__tag--state": date.state,
                },
                // date.state ? `bg-${this.states[date.state].color}` : null,
                date.state ? (this.states[date.state].color === "warning" ? "bg-primary" : `bg-${this.states[date.state].color}`) : null,
            ];
        },
        cellAction(cell) {
            if (!cell.hasOwnProperty("state")) return;
            this.dateAction(cell);
        },
        generateCalendar() {
            // reset calendar
            this.calendar = [];

            const d = this.$moment(this.items[0]?.date_slot); // 6 Novembre 2021
            const y = d.year(); // 2021
            const m = d.month(); // 10 (Janvier = 0)
            const date = this.$moment().year(y).month(m).date(1); // 1er Novembre 2021
            const today = this.$moment().format("YYYY-MM-DD"); // date du jour

            // Si le 1er jour du mois d'affichage n'est pas un lundi : traitement des cases précédentes (mois précédent)
            let weekday = date.day();
            if (weekday != 1) {
                let previous = date.subtract(1, "d").date(); // jour précédent (dernier jour du mois précédent)
                weekday = weekday - 1 < 0 ? 6 : weekday - 1;
                while (weekday > 0) {
                    this.calendar.unshift({
                        day: previous,
                        out: true,
                    });
                    weekday--;
                    previous--;
                }
                date.add(1, "d"); // 1er Novembre
            }

            // traitement des cases du mois en cours d'affichage
            while (date.month() === m) {
                this.calendar.push({
                    day: date.date(),
                    month: m + 1,
                    out: date.format("YYYY-MM-DD") < today,
                });
                date.add(1, "d");
            }

            // Si le dernier jour du mois d'affichage n'est pas un dimanche : traitement des cases suivantes (mois suivant)
            weekday = date.day(); // 3
            if (weekday !== 0) {
                let next = 1;
                while (weekday <= 7) {
                    this.calendar.push({
                        day: next,
                        month: m + 2 <= 11 ? m + 2 : 0,
                        out: true,
                    });
                    weekday++;
                    next++;
                }
            }

            // add dates state
            this.items.forEach((item) => {
                const cell = this.calendar.find((d) => {
                    return this.$moment(item.date_slot).date() === d.day && d.month === m + 1;
                });
                Object.assign(cell, item);
            });
        },
    },
};
</script>
<style scoped>
.zebra {
    background: repeating-linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 10px, rgba(0, 0, 0, 0.3) 10px, rgba(0, 0, 0, 0.3) 20px);
}

</style>
