<template>
    <app-page title="Vos prestations">
        <section>
            <center-container col-class="col-xs-no-gutter">
                <b-tabs>
                    <b-tab class="document-tab" title="À venir">
                        <b-row>
                            <div class="w-100 bg-white p-3 mb-sm-5">
                                <time-splitter :list="slots" split-by-month title="_Month_ _Y_" prop="date_slot" @changeDate="onDateChange" v-if="slots.length > 0">
                                    <template #default="splitterProps">
                                        <app-calendar class="mt-1" :items="splitterProps.split" :date-action="modalActionPlanning"> </app-calendar>
                                    </template>
                                </time-splitter>
                                <states-caption class="mt-3" />
                                <div class="d-flex justify-content-center align-items-center mt-2 mb-0">
                                    <strong class="mr-1">Total sur le mois : </strong>
                                    <div class="mr-1">{{ hours }}</div>
                                    <div class="ml-1" v-if="isGfs">- {{ kms }}km</div>
                                </div>
                            </div>
                            <b-card class="w-100 my-0 mb-sm-5">
                                <template #header>
                                    <p class="h4 text-center">À venir</p>
                                </template>
                                <div
                                    v-for="(slot, index) in upcomingSlots"
                                    class="d-flex justify-content-between align-items-center border-gray"
                                    :class="{
                                        'pt-2': index > 0,
                                        'pb-2 border-bottom': index < upcomingSlots.length - 1,
                                    }"
                                    :key="`upcoming-${index}`"
                                >
                                    <div class="pr-4 py-1">
                                        <p :class="[`text-${states.upcoming.color}`]">
                                            <span class="font-weight-bold">{{ formatDate(slot.date_slot, "_D_ _Month_") }}</span>
                                            - {{ formatTime(slot.start_time, "_H_h_Min_") }}-{{ formatTime(slot.end_time, "_H_h_Min_") }}
                                        </p>
                                        <p>
                                            <span v-if="slot.employee">
                                                {{ slot.employee.employee_lastname }}
                                                {{ slot.employee.employee_firstname }}
                                            </span>
                                            <span v-else>Pas d'intervenant</span>
                                            -
                                            <template v-if="slot.activity">{{ slot.activity.name }}</template>
                                        </p>
                                        <p v-if="slot.customer_instructions"><strong>Consigne : </strong>{{ slot.customer_instructions }}</p>
                                    </div>
                                    <b-link
                                        class="d-flex align-items-center text-nowrap"
                                        @click="
                                            () => {
                                                modalActionPlanning(slot);
                                            }
                                        "
                                    >
                                        Modifier
                                        <b-icon-plus-circle-fill class="h4 ml-1" />
                                    </b-link>
                                </div>
                            </b-card>
                        </b-row>
                    </b-tab>
                    <b-tab class="document-tab" title="Réalisées" :active="initOnValidation">
                        <app-list :interventions="interventions" :contrats="contrats" :current-contrat="currentContract" :date-action="modalActionPrestations"></app-list>
                    </b-tab>
                </b-tabs>
            </center-container>
        </section>
        <agenda-modal :data="modal" ref="agendaModal" @reportCreated="sendReport" @instructionAdded="addInstruction" @annulationAdded="addAnnulation"></agenda-modal>
    </app-page>
</template>

<script>
import { BIconPlusCircleFill } from "bootstrap-vue";
import AppPage from "@/components/layout/AppPage";
import CenterContainer from "@/components/layout/CenterContainer";
import TimeSplitter from "@/components/layout/elements/TimeSplitter";
import AppCalendar from "@/components/layout/elements/AppCalendar";
import formatDate from "@/mixins/format-date";
import formatTime from "@/mixins/format-time";
import StatesCaption from "@/components/layout/elements/StatesCaption";
import SimpleModal from "@/components/layout/elements/SimpleModal";
import AgendaModal from "@/components/pages/prestations/AgendaModal";
import AppList from "@/components/pages/prestations/AppList";
import store from "../../../store";
import prestationsService from "../../../services/prestationsService";
import AlertHandler from "@/alertHandler";

export default {
    components: {
        AppList,
        AgendaModal,
        StatesCaption,
        AppCalendar,
        TimeSplitter,
        CenterContainer,
        AppPage,
        SimpleModal,
        BIconPlusCircleFill,
    },
    mixins: [formatDate, formatTime],
    data() {
        return {
            modal: null,
            initOnValidation: false,
            states: store.state.prestationStates,
            slots: [],
            upcomingSlots: [],
            interventions: [],
            contrats: {},
            currentContract: null,
            selectedDate: {
                year: new Date().getFullYear(),
                month: new Date().getMonth(),
            },
            hours: "00h00",
            kms: 0,
        };
    },
    computed: {
        isGfs() {
            return location.host.includes("family");
        },
        // ...mapState({
        //   shifts: state => state.shifts,
        //   validationTab: state => state.validationTab
        // }),
        // upcomingShifts () {
        //   return this.shifts.filter(shift => shift.state === 'upcoming')
        // }
    },
    created() {
        // if (!this.validationTab) return
        this.currentContract = this.$route.params.currentContract ?? this.$route.params.currentContract;
        this.initOnValidation = this.$route.params.openValidation ?? this.$route.params.openValidation;
        // this.openValidation(false)
    },
    mounted() {
        this.getInterventions();
        this.getPlanning();
    },
    watch: {
        slots(val) {
            this.computeHoursKms(val);
        },
    },
    methods: {
        // ...mapMutations({
        //   openValidation: 'openValidation'
        // }),
        getInterventions() {
            prestationsService.getInterventions().then((response) => {
                this.contrats = response.data.data.contrats_id;

                this.interventions =
                    response.data.data.interventions?.filter((i) => {
                        if((i.canceled_category || i.cancelled) && !(i.billable || i.billed)) return false 
                        return true
                    }) ?? [];

                this.setState(this.interventions, "interventions");
            });
        },
        getPlanning() {
            prestationsService.getPlanning().then((response) => {
                this.slots = response.data.data.planning?.filter((i) => {
                        if((i.canceled_category || i.cancelled) && !(i.billable || i.billed)) return false 
                        return true
                    }) ?? [];
;

                this.upcomingSlots = this.slots.filter(
                    (s) => !s.canceled_category && s.date_slot > this.$moment().format("YYYY-MM-DD") && s.date_slot <= this.$moment().add(30, "days").format("YYYY-MM-DD")
                );

                this.setState(this.slots, "planning");
            });
        },
        sendReport(event) {
            prestationsService
                .sendReportToIntervention(event)
                .then((response) => {
                    if (response.data.etat == "OK") {
                        AlertHandler.show({ code: "reportSent" });
                    } else {
                        AlertHandler.show({ code: 4012 });
                    }
                })
                .catch((error) => {
                    AlertHandler.show({ code: 4013, error });
                });
        },
        addInstruction(event) {
            prestationsService
                .addNoteToIntervention(event)
                .then((response) => {
                    if (response.data.etat == "OK") {
                        const slot = this.slots.find((i) => i.idagenda_slot == event.id_slot);
                        if (slot) {
                            slot.customer_instructions = event.message;
                        }
                        AlertHandler.show({ code: "instructionSent" });
                    } else {
                        AlertHandler.show({ code: 4014 });
                    }
                })
                .catch((error) => {
                    AlertHandler.show({ code: 4015, error });
                });
        },
        addAnnulation(event) {
            prestationsService
                .cancelIntervention(event)
                .then((response) => {
                    if (response.data.etat == "OK") {
                        const slot = this.slots.find((i) => i.idagenda_slot == event.id_slot);
                        if (slot) {
                            slot.customer_instructions = event.message;
                        }
                        AlertHandler.show({ code: "cancelSent" });
                    } else {
                        AlertHandler.show({ code: 4020 });
                    }
                })
                .catch((error) => {
                    AlertHandler.show({ code: 4021, error });
                });
        },
        modalActionPlanning(data) {
            this.modal = {
                title: this.formatDate(data.date_slot, "_Day_ _D_ _Month_ _Y_"),
                time: `${this.formatTime(data.start_time_billable ?? data.start_time, "_H_h_Min_")} - ${this.formatTime(data.end_time_billable ?? data.end_time, "_H_h_Min_")} ${isGfs ? `- ${data.kms ?? 0}km` : ''}`,
                name: `${data.employee?.employee_lastname ?? "Pas d'intervenant"} ${data.employee?.employee_firstname ?? ""} - ${data.activity?.name ?? "Sans activité"}`,
                state: data.state,
                id: data.idagenda_slot,
                type: "slot",
                raw_date: data.date_slot,
                comment: data.customer_instructions,
            };

            if (data.state === "verifying" && data.idintervention_ticking === null) this.modal.comment = "L'intervention planifiée n'est associée à aucun pointage.";

            this.$nextTick(() => {
                this.$refs.agendaModal.modalAction();
            });
        },
        modalActionPrestations(data) {
            this.modal = {
                title: this.formatDate(data.intervention_date, "_Day_ _D_ _Month_ _Y_"),
                time: `${this.formatTime(data.start_time_billable, "_H_h_Min_")} - ${this.formatTime(data.end_time_billable, "_H_h_Min_")} ${isGfs ? `- ${data.kms ?? 0}km` : ''}`,
                name: data.employee_lastname + " " + data.employee_firstname + " - " + data.name,
                state: data.state,
                id: data.idintervention_ticking,
                type: "pointage",
                raw_date: data.intervention_date,
                invoice: data.invoice,
                comment: null,
            };
            this.$nextTick(() => {
                this.$refs.agendaModal.modalAction();
            });
        },
        setState(data, origin) {
            data.forEach((el) => {
                switch (origin) {
                    case "planning":
                        if ((el.canceled_category || el.cancelled) && (el.billable || el.billed)) el.state = "cancelled";
                        else if (el.date_slot > this.$moment().format("YYYY-MM-DD")) el.state = "upcoming";
                        else if (el.date_slot < this.$moment().format("YYYY-MM-DD") && (!el.canceled_category && !el.cancelled && !el.billable && !el.billed)) el.state = 'verifying'
                        else el.state = "validated";

                        break;
                    case "interventions":
                        if (el.cancelled) el.state = "cancelled";
                        else if (el.validation_status == 1) el.state = "validated";
                        else el.state = "verifying";

                        break;
                    default:
                        el.state = null;
                }
            });
        },
        onDateChange(date) {
            this.selectedDate = date;
            this.computeHoursKms(this.slots);
        },
        computeHoursKms(slots) {
            const month = ("0" + (this.selectedDate.month + 1)).substr(-2);

            const start = `${this.selectedDate.year}-${month}-01`;
            const end = `${this.selectedDate.year}-${month}-31`;

            this.kms = slots.reduce((acc, curr) => {
                if (curr.date_slot < start || curr.date_slot > end) return acc;
                return acc + curr.kms * 1;
            }, 0);

            const milli = slots.reduce((acc, curr) => {
                if (curr.date_slot < start || curr.date_slot > end) return acc;
                if (!curr.end_time_billable || !curr.start_time_billable) return acc;

                const diff = this.$moment.duration(this.$moment(curr.date_slot + " " + curr.end_time_billable).diff(this.$moment(curr.date_slot + " " + curr.start_time_billable)));

                if (!diff.isValid()) return acc;

                return acc + diff.asMilliseconds();
            }, 0);

            this.hours = this.$moment.utc(milli).format("HH[h]mm");
        },
    },
};
</script>
<style scoped>
>>> .nav-link {
    color: white;
}
</style>
```