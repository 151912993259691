<template>
    <div class="states-caption">
        <div v-for="(state, index) in activeStates"
             class="d-flex align-items-center"
             :class="{'pr-2': index < state.length - 1}">
            <div class="states-caption__color"
                 :class="[`bg-${state.color}`]"/>
            <p class="states-caption__name">{{ state.text }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'states-caption',
    props: {
        inPlanning: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            states: this.$appVersion('states')
        }
    },
    computed: {
        activeStates() {
            const tmp = {};
            Object.keys(this.states).forEach(key => {
                const state = this.states[key];
                if (this.inPlanning && state.planning || !this.inPlanning && state.presta) {
                    tmp[key] = state;
                }
            });
            return tmp;
        }
    }
}
</script>