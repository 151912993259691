export default {
    methods: {
        formatTime(time, template) {
            if (!time) return "";
            const arr = time.split(':')
            return template
                .replace('_H_', arr[0])
                .replace('_M_', arr[1])
                .replace('_Min_', Number(arr[1]) > 0 ? arr[1] : '')
                .replace('_S_', arr[2])
        }
    }
}